export default {
  "headlines": {
    "weekOfMeals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a Week of Meals"])},
    "emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🥘"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "recipes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipes"])},
    "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions"])},
    "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingredients"])},
    "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
    "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
    "weekPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week Plan"])},
    "shoppingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping List"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items"])}
  },
  "labels": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old Password"])},
    "repeateNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeate new password"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
    "typeToSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type to search..."])},
    "prepTimeMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparation Time [min]"])},
    "cookTimeMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooking Time [min]"])},
    "prepTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparation Time"])},
    "cookTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooking Time"])},
    "numberOfPortions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Portions"])},
    "chooseCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose category"])},
    "chooseUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose unit"])},
    "addItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Item"])},
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created by"])},
    "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by"])},
    "addInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add one step"])},
    "addIngredientToInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add ingredient to step"])},
    "addToWeekPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to week plan"])},
    "addTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a tag"])},
    "addCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a category"])},
    "editRecipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit recipe"])},
    "clearWeekPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear the Week Plan"])},
    "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekday"])},
    "recipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipe"])},
    "portions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portions"])},
    "generateShoppingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate shopping list"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "recipeCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipe Category"])},
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
    "copyShoppingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy shopping list"])},
    "loadMoreRecipes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more recipes"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import"])},
    "chooseSource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Source"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
    "itemCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Category"])},
    "alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias"])},
    "addAlias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add alias"])},
    "converter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Converter"])},
    "addConverter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add converter"])},
    "factor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factor"])},
    "showHidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show hidden"])},
    "toSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the settings"])}
  },
  "alerts": {
    "imageToBig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The provided image is to big!"])},
    "loginFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to login!"])},
    "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong Password"])},
    "areYouSureDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure that you want to delete this?"])},
    "noItemFoundFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No item found for"])},
    "noUnitFoundFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No fitting unit was found for"])},
    "matchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while matching"])},
    "errorSaving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while saving"])},
    "successSaving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved successfully"])},
    "errorDeleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while deleting"])},
    "successDeleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted successfully"])},
    "errorParsing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while parsing"])},
    "request": {
      "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not authorized!"])}
    },
    "db": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully connected to the Database."])}
    }
  },
  "login": {
    "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please sign in"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or register a new account"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])}
  },
  "register": {
    "welcomeTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to "])},
    "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])}
  },
  "recipes": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new recipe"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Recipe"])}
  },
  "units": {
    "mass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["g"])},
    "volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ml"])},
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pcs."])},
    "mass1000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kg"])},
    "volume1000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["l"])},
    "pinch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pinch"])},
    "teaspoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ts"])},
    "tablespoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tb"])}
  },
  "settings": {
    "account": {
      "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])}
    },
    "shoppingList": {
      "changeOrder": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change order"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set the order of your shopping list by item categories"])}
      },
      "hideItem": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide item"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide items in your shopping List. You can also specify a amount at which they should appear regardless."])}
      }
    },
    "items": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can change or create items."])}
    }
  },
  "itemCategory": {
    "cerealProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cereal products"])},
    "fruitsAndVegetables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fruits/Vegetables"])},
    "spicesAndSauces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spices/Sauces"])},
    "refrigerated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refrigerated"])},
    "frozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frozen"])},
    "meatAndFish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meat/Fish"])},
    "cannedProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canned products"])},
    "stapleFood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staple food"])},
    "bakingIngredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baking Ingredients"])},
    "drinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drinks"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
  },
  "tags": {
    "vegetarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vegetarian"])},
    "vegan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vegan"])},
    "glutenFree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gluten free"])},
    "lactoseFree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lactose free"])},
    "highProtein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["high protein"])},
    "lowCarb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["low carb"])},
    "onePot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["one pot"])},
    "thermomix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thermomix"])}
  },
  "recipeCategory": {
    "mainDishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main dishes"])},
    "desserts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desserts"])},
    "soups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soups"])},
    "cakesPastries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cakes & Pastries"])},
    "casserolesGratins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casseroles & Gratins"])},
    "breadBakedGoods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bread & Baked Goods"])},
    "dipsSauces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dips & Sauces"])},
    "breakfast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breakfast"])},
    "drinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drinks"])},
    "salads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salads"])},
    "snacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snacks"])}
  },
  "weekdays": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])}
  },
  "weekdaysShort": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wed"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat"])}
  }
}