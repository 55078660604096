export default {
  "headlines": {
    "weekOfMeals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a Week of Meals"])},
    "emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🥘"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
    "recipes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezepte"])},
    "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zubereitung"])},
    "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zutaten"])},
    "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
    "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorien"])},
    "weekPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wochenplan"])},
    "shoppingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einkaufsliste"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zutaten"])}
  },
  "labels": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Addresse"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
    "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altes Passwort"])},
    "repeateNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort wiederholen"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erledigt"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen"])},
    "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anleitung"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild"])},
    "typeToSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tippe um zu Suchen..."])},
    "prepTimeMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Zeit [min]"])},
    "cookTimeMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passive Zeit [min]"])},
    "prepTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Zeit"])},
    "cookTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passive Zeit"])},
    "numberOfPortions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Portionen"])},
    "chooseCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie auswählen"])},
    "chooseUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheit auswählen"])},
    "addItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zutat hinzufügen"])},
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt von"])},
    "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
    "addInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schritt hinzufügen"])},
    "addIngredientToInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zutat zum Schritt hinzufügen"])},
    "addToWeekPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Wochenplan hinzufügen"])},
    "addTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag hinzufügen"])},
    "addCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie hinzufügen"])},
    "editRecipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezept bearbeiten"])},
    "clearWeekPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wochenplan löschen"])},
    "weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wochentag"])},
    "recipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezept"])},
    "portions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portionen"])},
    "generateShoppingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einkaufsliste erstellen"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "recipeCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezept Kategorie"])},
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
    "copyShoppingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einkaufsliste kopieren"])},
    "loadMoreRecipes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Rezepte laden"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importieren"])},
    "chooseSource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle eine Quelle"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheit"])},
    "itemCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zutatskategorie"])},
    "alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias"])},
    "addAlias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias hinzufügen"])},
    "converter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umrechnung"])},
    "addConverter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umrechnung hinzufügen"])},
    "factor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktor"])},
    "showHidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgeblendete ansehen"])},
    "toSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu den Einstellungen"])}
  },
  "alerts": {
    "imageToBig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Bild ist zu groß!"])},
    "loginFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung fehlgeschlagen!"])},
    "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsches Passwort"])},
    "areYouSureDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bist du dir sicher, dass du das löschen willst?"])},
    "noItemFoundFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Zutat gefunden für"])},
    "noUnitFoundFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine passende Einheit gefunden für"])},
    "matchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Abgleich von"])},
    "errorSaving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Speichern ist ein Fehler aufgetreten"])},
    "successSaving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gespeichert"])},
    "errorDeleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Löschen ist ein Fehler aufgetreten"])},
    "successDeleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelöscht"])},
    "errorParsing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Importieren ist ein Fehler aufgetreten"])},
    "request": {
      "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht autorisiert!"])}
    },
    "db": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich mit der Datenbank verbunden."])}
    }
  },
  "login": {
    "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte Anmelden"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oder registriere einen neues Konto"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])}
  },
  "register": {
    "welcomeTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen bei "])},
    "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto erstellen"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast schon ein Konto?"])}
  },
  "recipes": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezept hinzufügen"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Rezept"])}
  },
  "units": {
    "mass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["g"])},
    "volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ml"])},
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stk."])},
    "mass1000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kg"])},
    "volume1000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["l"])},
    "pinch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pr."])},
    "teaspoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TL"])},
    "tablespoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL"])}
  },
  "settings": {
    "account": {
      "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])}
    },
    "shoppingList": {
      "changeOrder": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reihenfolge ändern"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kannst du die Reihenfolge deiner Produkte in der Einkaufsliste per Kategorie festlegen."])}
      },
      "hideItem": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zutaten verbergen"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verberge Zutaten in der Einkaufsliste. Zusätzlich kannst du eine Menge angeben bei der die Zutaten dann doch angezeigt werden sollen."])}
      }
    },
    "items": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kannst du Zutaten bearbeiten oder hinzufügen."])}
    }
  },
  "itemCategory": {
    "cerealProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getreideprodukte"])},
    "fruitsAndVegetables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obst/Gemüse"])},
    "spicesAndSauces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewürze/Soßen"])},
    "refrigerated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekühltes"])},
    "frozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefrorenes"])},
    "meatAndFish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fleisch/Fisch"])},
    "cannedProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosenprodukte"])},
    "stapleFood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundnahrungsmittel"])},
    "bakingIngredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backzutaten"])},
    "drinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getränke"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderes"])}
  },
  "tags": {
    "vegetarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vegetarisch"])},
    "vegan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vegan"])},
    "glutenFree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["glutenfrei"])},
    "lactoseFree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["laktosefrei"])},
    "highProtein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["viele Proteine"])},
    "lowCarb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wenig Kohlenhydrate"])},
    "onePot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["one Pot"])},
    "thermomix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thermomix"])}
  },
  "recipeCategory": {
    "mainDishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptgerichte"])},
    "desserts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desserts"])},
    "soups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppen"])},
    "cakesPastries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuchen & Gebäck"])},
    "casserolesGratins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufläufe & Gratins"])},
    "breadBakedGoods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brot & Backwaren"])},
    "dipsSauces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dips & Soßen"])},
    "breakfast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frühstück"])},
    "drinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getränke"])},
    "salads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salate"])},
    "snacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snacks"])}
  },
  "weekdays": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonntag"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montag"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstag"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittwoch"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnerstag"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freitag"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samstag"])}
  },
  "weekdaysShort": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mo"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fr"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sa"])}
  }
}